<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      scrollable
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>User Permissions</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height:400px">

          <v-container>
            <v-treeview 
                selectable
                :items="$store.getters['layout/menuItems']" 
                item-key="name"
                item-val="name"
                open-all
                selection-type="independent"
                return-object
                v-model="permissions"
                item-disabled="locked"
            >
                <template v-slot:label="{ item }">
                    <v-icon
                        small
                        v-if="item.icon"
                        :color="item.color"
                        class="mr-1"
                    >
                        {{ item.icon }}
                    </v-icon>
                    {{ item.name }}
                </template>
            </v-treeview>
          </v-container>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red lighten-1"
            dark
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            dark
            :loading="waitingForSave"
            @click="saveUserPermissions"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Utils from '@/utils'

  export default {
    props: ["user"],
    data: () => ({
      dialog: false,
      permissions: [],
      waitingForSave: false,
    }),
    watch: {
        user(user) {
          if (user == null) return
          let permissions = JSON.parse(user.permissions)
          if (permissions != null) this.permissions = permissions
          else this.permissions = []
        }
    },
    methods: {
        async saveUserPermissions() {
            this.waitingForSave = true
            let res = await this.$store.dispatch('user/saveUser', {
                url: 'update-permission',
                data: {permissions: this.permissions, id: this.user.id}
            })
            if (res) {
                this.dialog = false
                let checkAuth = await this.$store.dispatch('auth/checkAuth')
                if (checkAuth.success) this.loadMenu(checkAuth.user_type, checkAuth.permissions)
            }
            this.waitingForSave = false
            this.permissions = []
        },

        loadMenu(user_type, permittedMenuItems) {
            let menuItems = this.$store.getters['layout/menuItems']
            if (user_type == 'super_admin') {
                this.$store.commit('auth/populatePermittedMenuItems', menuItems)
            } else {
                if (menuItems.length == 0 || permittedMenuItems.length == 0) return
                let pMenuItems = Utils.makeNestedMenu(menuItems, permittedMenuItems)
                this.$store.commit('auth/populatePermittedMenuItems', pMenuItems)
            }
        }
    },
  }
</script>

<style lang="scss" scoped>

</style>