<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12">
                <v-form ref="userForm" @submit.prevent="saveUser" class="custom-form">
                    <v-card :elevation="1" color="blue lighten-5">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-toolbar color="#546E7A" dark :elevation="1" height="30px">
                                    <v-toolbar-title class="subtitle-2">User</v-toolbar-title>
                                </v-toolbar>
                                <v-card-text class="py-0 mt-1">
                                    <v-row dense justify="center">
                                        <v-col cols="4">
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Full Name</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        v-model.trim="user.name"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Username</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        v-model.trim="user.username"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">E-Mail</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model.trim="user.email"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3" class="text-right pr-2">Phone</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        v-model.trim="user.phone"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3" class="text-right pr-2">User Type</v-col>
                                                <v-col cols="9">
                                                    <v-combobox
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        :items="userTypes"
                                                        item-text="display_text"
                                                        item-value="type"
                                                        v-model="selectedUserType"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3" class="text-right pr-2">Password</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => validatePhone(v)]"
                                                        v-model.trim="user.password"
                                                        type="password"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="3"></v-col>
                                                <v-col cols="9">
                                                    <v-row no-gutters>
                                                         <v-col cols="6" class="pr-1">
                                                            <v-btn @click="resetForm" height="30px" dark block color="deep-orange">Clear</v-btn>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-btn type="submit" :loading="waitingForSave" height="30px" dark block color="light-blue darken-2">Save</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <v-row dense class="mt-1">
            <v-col cols="12" class="pb-0">
                <v-data-table
                    class="custom-data-table elevation-1"
                    dense
                    :headers="userHeaders"
                    :loading="$store.getters['user/loading']"
                    :items="$store.getters['user/users']"
                    :search="searchUser"
                >
                    <template v-slot:top>
                        <v-toolbar dense color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">User List</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    placeholder="Search User"
                                    append-icon="mdi-magnify"
                                    style="width:300px;"
                                    v-model="searchUser"
                                >
                                </v-text-field>
                            </v-form>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="editUser(item)" color="primary" v-on="on">mdi-circle-edit-outline</v-icon>
							</template>
							<span>Edit</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon v-if="($store.getters['auth/userType'] == 'super_admin') && (item.user_type != 'super_admin')" small @click="openDeleteDialog(item.id)" color="error" v-on="on">mdi-delete-circle-outline</v-icon>
							</template>
							<span>Delete</span>
						</v-tooltip>
						<v-tooltip v-if="($store.getters['auth/userType'] == 'super_admin') && (item.user_type != 'super_admin')" bottom>
							<template v-slot:activator="{ on }">
								<v-icon @click="openPermissionDialog(item)" small color="#000" v-on="on">mdi-account-key</v-icon>
							</template>
							<span>Permission</span>
						</v-tooltip>
					</template>
                </v-data-table>
            </v-col>
        </v-row>

        <delete-confirm ref="deleteDialog" @confirm="deleteUser"></delete-confirm>
        <user-permission :user="selectedUserForPermission" ref="permissionDialog"></user-permission>

    </v-container>
</template>

<script>
import DeleteConfirm from '@/components/Confirm'
import UserPermission from '@/components/UserPermission'

export default {
    name: 'User',
    components: {
        DeleteConfirm,
        UserPermission,
    },
    data: ()=> ({
        userHeaders: [
            { text: 'Name', value: 'name' },
            { text: 'Username', value: 'username' },
            { text: 'Phone', value: 'phone' },
            { text: 'E-Mail', value: 'email' },
            { text: 'Type', value: 'user_type' },
            { text: 'Action', value: 'action' },
        ],
        searchUser: '',
        userTypes: [
            { display_text: 'Super Admin', type: 'super_admin' },
            { display_text: 'Admin', type: 'admin' },
            { display_text: 'User', type: 'user' },
        ],
        selectedUserType: null,
        user: {
            name: '',
            username: '',
            phone: '',
            email: '',
            password: '',
        },
        waitingForSave: false,
        userEditId: null,
        userDeleteId: null,
        selectedUserForPermission: null,
    }),
    created() {
        this.$store.dispatch('user/getUsers')
    },
    methods: {
        async saveUser() {
            if (!this.$refs.userForm.validate()) return
            this.waitingForSave = true
            if (this.selectedUserType != null) this.user.user_type = String(this.selectedUserType.type).toLowerCase()
            if (this.userEditId == null) {
                let res = await this.$store.dispatch('user/saveUser', {
                    url: 'add-user',
                    data: this.user
                })
                if (res) this.resetForm()
            } else {
                let res = await this.$store.dispatch('user/saveUser', {
                    url: 'update-user',
                    data: {...this.user, id: this.userEditId}
                })
                if (res) this.resetForm()
            }
            this.waitingForSave = false
        },

        editUser(item) {
            this.userEditId = item.id
            this.userTypes.forEach(el => {
                if (el.type == String(item.user_type).toLowerCase()) 
                this.selectedUserType = el
            })
            Object.keys(this.user).map(k => this.user[k] = item[k])
        },

        openDeleteDialog(id) {
            this.userDeleteId = id
            this.$refs.deleteDialog.dialog = true
        },

        deleteUser() {
            this.$store.dispatch('user/deleteUser', {id: this.userDeleteId})
            this.$refs.deleteDialog.dialog = false
            this.userDeleteId = null
        },
        
        resetForm() {
            this.selectedUserType = null
            Object.keys(this.user).map(k => this.user[k] = '')
            this.userEditId = null
            this.$refs.userForm.resetValidation()
        },

        openPermissionDialog(user) {
            this.selectedUserForPermission = user
            this.$refs.permissionDialog.dialog = true
        },

        validatePhone(v) {
            if (v == '' && this.userEditId == null) return false
            else return true
        }
    }
}
</script>

<style lang="scss" scoped>
    .v-icon.v-icon {
       font-size: 18px !important;
       top: -3px !important;
    }
    .v-icon.v-icon[data-v-1f38b4e5] {
        font-size: 14px !important;
        top: 0px !important; 
    }
    .v-messages {
        flex: 1 1 auto;
        font-size: 12px;
        min-height: 0px !important;
        max-height: 0px!important;
        min-width: 1px;
        position: relative;
    }
</style>